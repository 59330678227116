import { useQuery } from '@tanstack/react-query'
import OrganizationService from '../../services/Organization.service'
import { OrganizationResponse } from '../../model/Organizations'

type OrganizationsOptions = {
  userId?: string
  page?: number
  size?: number
}

export const useMyOrganizations = ({ userId, ...payload }: OrganizationsOptions) => {
  // return useInfiniteQuery<OrganizationResponse>({
  //   queryKey: ['my-organizations', userId, payload],
  //   queryFn: ({ pageParam }) => OrganizationService.listMy(pageParam as number, payload.size),
  //   initialPageParam: 0,
  //   getNextPageParam: (lastPage, _2, lastPageParam) => {
  //     if (lastPage?.organizations.length === 0) {
  //       return undefined
  //     }
  //     return Number(lastPageParam) + 1
  //   },
  //   enabled: !!userId,
  // })

  return useQuery<OrganizationResponse>({
    queryKey: ['my-organizations', userId, payload],
    queryFn: () => OrganizationService.listMy(payload.page, payload.size),
    enabled: !!userId,
  })
}
