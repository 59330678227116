import React, { FC } from 'react'
import AppLogoBackOffice from '../../assets/AppLogoBackOffice'
import AppLogoDrive from '../../assets/AppLogoDrive'
import AppLogoOrg from '../../assets/AppLogoOrg'
import AppLogoProcess from '../../assets/AppLogoProcess'
import AppLogoReport from '../../assets/AppLogoReport'
import { Link } from 'react-router-dom'
import { Organization } from '../../model/Organizations'

type AppsPanelProps = {
  org: Organization
}
const AppsPanel: FC<AppsPanelProps> = ({ org }) => {
  return (
    <div className='py-3 bg-white rounded-[14px] flex-row flex shadow-[1px_0px_18px_-4px_rgba(0,0,0,0.2)] px-4'>
      {org.appAccess?.map((app, index) => (
        <div
          key={index}
          className='flex flex-col items-center justify-center '
          style={index === org.appAccess?.length - 1 ? { marginRight: '0rem' } : { marginRight: '2.5rem' }}
        >
          {app.code.includes('s-backoffice') && (
            <Link to={`${org.apps.find((app) => app.code === 's-backoffice')?.url}/${org.organizationCode}`}>
              <AppLogoBackOffice />
            </Link>
          )}
          {app.code.includes('s-drive') && (
            <Link to={`${org.apps.find((app) => app.code === 's-drive')?.url}/${org.organizationCode}`}>
              <AppLogoDrive />
            </Link>
          )}
          {app.code.includes('s-organization') && (
            <Link to={`${org.apps.find((app) => app.code === 's-organization')?.url}/${org.organizationCode}`}>
              <AppLogoOrg />
            </Link>
          )}
          {app.code.includes('s-process') && (
            <Link to={`${org.apps.find((app) => app.code === 's-process')?.url}/${org.organizationCode}`}>
              <AppLogoProcess />
            </Link>
          )}
          {app.code.includes('s-report') && (
            <Link to={`${org.apps.find((app) => app.code === 's-report')?.url}/${org.organizationCode}`}>
              <AppLogoReport />
            </Link>
          )}
        </div>
      ))}
    </div>
  )
}

export default AppsPanel
