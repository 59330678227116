import { useQuery } from '@tanstack/react-query'
import UserService from '../../services/UserService'
import { UserProfile } from '../../model/User'
type UserOptions = {
  userId?: string
}
export const useProfile = ({ userId }: UserOptions) => {
  return useQuery<UserProfile>({
    queryKey: ['user', userId],
    queryFn: () => UserService.profile(),
    enabled: !!userId,
  })
}
