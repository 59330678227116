import React, { FC } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Portal from '../pages/Portal'
import Logout from '../pages/Logout'

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Portal />} />
        <Route path='/:org' element={<Portal />} />
        <Route path='/logout' element={<Logout />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
