import { useMutation, useQueryClient } from '@tanstack/react-query'
import OrganizationService from '../../../services/Organization.service'
import { CreateOrganizationRequest } from '../../../model/Organizations'

export const useCreateOrganization = () => {
  const queryClient = useQueryClient()
  return useMutation<any, Error, CreateOrganizationRequest>({
    mutationKey: ['createOrganization'],
    mutationFn: (payload) => OrganizationService.create(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['my-organizations'] })
      // queryClient.invalidateQueries({ queryKey: ['listOrganizations'] })
      //queryClient.invalidateQueries({ queryKey: ['listApplications'] })
    },
  })
}
