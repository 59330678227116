import http from './http-common'
import { OrganizationResponse } from '../model/Organizations'
import { Application, UserProfile } from '../model/User'

class UserService {
  async listOrganizations(page = 0, size = 20): Promise<OrganizationResponse> {
    return (await http.get('/my-organizations', { params: { page, size } })).data
  }

  async listApplications(organization: string): Promise<Application[]> {
    return (await http.get(`/${organization}/applications/access`)).data
  }
  async profile(): Promise<UserProfile> {
    return (await http.get('/profile')).data
  }
}

export default new UserService()
