import { Configuration, PublicClientApplication } from '@azure/msal-browser'

const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0

export const requestScopes = ['openid', 'fd1442d1-cbcc-4b73-a893-a61b8d3d4782']

const flowSub = () => {
  return process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE !== 'prod'
    ? `_${process.env.REACT_APP_STAGE?.toUpperCase()}`
    : ''
}

export const msalConfig: Configuration = {
  auth: {
    clientId: 'fd1442d1-cbcc-4b73-a893-a61b8d3d4782',
    authority: `https://login.sistemiv.com/f993cc02-b280-412a-86e1-c2b0aa67d1d3/B2C_1_SIGN_UP_AND_SIGN_IN${flowSub()}`,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    knownAuthorities: ['login.sistemiv.com'],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    allowRedirectInIframe: true,
  },
}

export const msalInstance = new PublicClientApplication(msalConfig)
