import { AccountInfo } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'
import React, { FC, PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react'
//import { Application } from '../model/User'
import { useNavigate, useParams } from 'react-router-dom'
import { Organization } from '../model/Organizations'
import UserService from '../services/UserService'

interface AuthContextType {
  account: AccountInfo | null
  organizations: Organization[]
  //applications: Application[]
  isLoading: boolean
}

const initialContext = {
  account: null,
  organizations: [],
  //applications: [],
  isLoading: true,
}

export const AuthContext = createContext<AuthContextType>(initialContext)

const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { accounts } = useMsal()
  const navigate = useNavigate()
  const [organizations, setOrganizations] = useState<Organization[]>([])
  //const [applications, setApplications] = useState<Application[]>([])
  const account = useMemo(() => accounts[0] ?? null, [accounts])
  const { org } = useParams()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    UserService.listOrganizations()
      .then((response) => {
        const orgs = response?.organizations
        setOrganizations(orgs)

        if (!orgs || orgs?.length === 0) return
        // if (!org) {
        //   navigate(`/${orgs[0].organizationCode}`, { replace: true })
        // }
      })
      .finally(() => {
        // if (organizations?.length === 0) {
        setIsLoading(false)
        // }
      })

    // if (org) {
    //   UserService.listApplications(org)
    //     .then((value) => {
    //       setApplications(value)
    //     })
    //     .finally(() => {
    //       setIsLoading(false)
    //     })
    // }
  }, [org, navigate, organizations?.length])

  return <AuthContext.Provider value={{ account, organizations, isLoading }}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
