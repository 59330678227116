import React, { FC } from 'react'
import Layout from '../components/layout/Layout'
import ErrorHandlingInterceptor from '../components/error-handling/ErrorHandlingInterceptor'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { AuthError, InteractionType } from '@azure/msal-browser'
import { requestScopes } from '../services/AuthConfig'
import AuthContextProvider from '../contexts/AuthContext'
import OrgsOverview from '../components/orgs-overview/OrgsOverview'

const Portal: FC = () => {
  const params = new URL(document.location.href).searchParams
  const app = params.get('app')

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{ scopes: requestScopes, state: app ?? '' }}
      errorComponent={ErrorComponent}
    >
      <ErrorHandlingInterceptor>
        <AuthContextProvider>
          <Layout>
            <OrgsOverview />
          </Layout>
        </AuthContextProvider>
      </ErrorHandlingInterceptor>
    </MsalAuthenticationTemplate>
  )
}

const ErrorComponent: FC<{ error: AuthError | null }> = ({ error }) => {
  return <div>{error?.errorMessage}</div>
}

export default Portal
