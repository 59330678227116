import React, { FC } from 'react'
import EyeDropperIcon from '../../assets/EyeDropperIcon'

const NoAppsPanel: FC = () => (
  <div className='py-3 px-3 bg-white rounded-[14px] flex-row flex shadow-[1px_0px_18px_-4px_rgba(0,0,0,0.2)]'>
    <div className='flex flex-row p-1 items-center'>
      <EyeDropperIcon />
      <div className='flex flex-col ml-2'>
        <p className='text-[14px]'>You dont have access to any products yet.</p>
        <p className='text-[14px]'>Please contact your Organization Admin</p>
      </div>
    </div>
  </div>
)

export default NoAppsPanel
