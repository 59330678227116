import {
  Button,
  JoinOrganizationModal,
  CreateOrganizationModal,
  SuccessNotification,
  SpinnerIcon,
} from '@sistemiv/s-components'
import React, { FC, useContext, useState } from 'react'
import AppLogoSuite from '../../assets/AppLogoSuite'
import OrgsList from './OrgsList'
import { AuthContext } from '../../contexts/AuthContext'
import { useJoinOrganization } from '../../repositories/organizations/mutations/join-organization.mutation'
import { useCreateOrganization } from '../../repositories/organizations/mutations/create-organization.mutation'
import { useOrganizations } from '../../repositories/organizations/organizations.respository'
import { useTranslation } from 'react-i18next'
import { imageBase } from '../../services/http-common'
import { useSearchParams } from 'react-router-dom'
import { useMyOrganizations } from '../../repositories/organizations/organizations-my.respository'

const OrgsOverview: FC = () => {
  const { isLoading, account } = useContext(AuthContext)
  const [search, setSearch] = useState('')
  const { t } = useTranslation()

  const { data: orgPages, fetchNextPage: fetchNextOrgs } = useOrganizations({
    userId: account?.homeAccountId,
    search: search,
  })
  const { data: myOrgs, isLoading: isOrgsLoading } = useMyOrganizations({
    userId: account?.homeAccountId,
  })

  const [searchParams] = useSearchParams()
  const subscriptionId = searchParams.get('subscriptionId')
  const providerType = searchParams.get('providerType')
  console.log(subscriptionId)
  console.log(providerType)

  const { mutate: joinOrganization, isPending: isJoining } = useJoinOrganization()
  const [notificationMessage, setNotificationMessage] = useState('')
  const [notificationModalOpen, setNotificationModalOpen] = useState(false)

  const { mutate: createOrganization, isPending: isCreating } = useCreateOrganization()

  const [createOrganizationOpen, setCreateOrganizationOpen] = useState(false)
  const [joinOrganizationOpen, setJoinOrganizationOpen] = useState(false)

  if (isLoading || isOrgsLoading) {
    return (
      <div className='bg-[#E8EAEF] min-h-[calc(100vh-4.185rem)] max-h-[calc(100vh_-4.185rem)] flex justify-center align-center items-center border-0 flex-col pb-20'>
        <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
      </div>
    )
  }

  return (
    <div className='bg-[#E8EAEF] min-h-[calc(100vh-4.185rem)] max-h-[calc(100vh_-4.185rem)] flex align-center items-center border-0 flex-col pb-20 overflow-auto '>
      <div>
        <div className=' mb-[100px] mt-[100px]'>
          <AppLogoSuite />
        </div>
        <div>
          <div className='flex items-center mb-14'>
            <Button
              onClick={() => setCreateOrganizationOpen(true)}
              className='min-w-[160px] flex justify-center border border-[color:var(--secondary,#467FFE)] rounded-md border-solid text-[#467FFE] '
            >
              {t('createOrg')}
            </Button>
            <Button />
            <Button
              onClick={() => setJoinOrganizationOpen(true)}
              className='min-w-[160px] flex justify-center border border-[color:var(--primary,#0096FE)] bg-[color:var(--primary,#0096FE)] rounded-md border-solid text-white'
            >
              {t('joinOrg')}
            </Button>
          </div>
          {myOrgs && myOrgs?.organizations?.length > 0 && <OrgsList organizations={myOrgs?.organizations} />}
        </div>
        {joinOrganizationOpen && (
          <JoinOrganizationModal
            open={joinOrganizationOpen}
            setOpen={setJoinOrganizationOpen}
            onJoin={(organization) => {
              joinOrganization(
                { code: organization.code },
                {
                  onSuccess: () => {
                    setJoinOrganizationOpen(false)
                    setNotificationMessage(t('joinOrgMsg') as string)
                    setNotificationModalOpen(true)
                  },
                },
              )
            }}
            isJoining={isJoining}
            fetchNext={() => fetchNextOrgs()}
            setSearch={(value: string) => setSearch(value)}
            options={
              orgPages?.pages
                .flat(Infinity)
                .map((r) => r.organizations)
                .flat()
                ?.map((o) => ({
                  id: o.organizationCode,
                  code: o.organizationCode,
                  image: `${imageBase}/${o.organizationCode}/76`,
                  name: o.name,
                })) ?? []
            }
          />
        )}
        {notificationModalOpen && (
          <SuccessNotification
            open={notificationModalOpen}
            setOpen={setNotificationModalOpen}
            message={notificationMessage}
          />
        )}

        {createOrganizationOpen && (
          <CreateOrganizationModal
            open={createOrganizationOpen}
            setOpen={setCreateOrganizationOpen}
            onContinue={(name: string, subdomain: string) => {
              subscriptionId && providerType
                ? createOrganization(
                    { name, code: subdomain, subscriptionId, providerType },
                    {
                      onSuccess: () => {
                        setCreateOrganizationOpen(false)
                        setNotificationMessage(t('createOrgMsg', { name: name }) as string)
                        setNotificationModalOpen(true)
                      },
                    },
                  )
                : createOrganization(
                    { name, code: subdomain },
                    {
                      onSuccess: () => {
                        setCreateOrganizationOpen(false)
                        setNotificationMessage(t('createOrgMsg', { name: name }) as string)
                        setNotificationModalOpen(true)
                        // navigate(`/${subdomain}`, { replace: true })
                      },
                    },
                  )
            }}
            isCreating={isCreating}
          />
        )}
      </div>
    </div>
  )
}

export default OrgsOverview
