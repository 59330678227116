import React, { FC, PropsWithChildren } from 'react'
import TopNav from './TopNav'

const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='h-screen'>
      <TopNav />
      <div className='w-full h-[calc(100vh_-_4.185rem] overflow-hidden'>{children}</div>
    </div>
  )
}

export default Layout
