import http from '../services/http-common'

import { CreateOrganizationRequest, OrganizationResponse } from '../model/Organizations'

class OrganizationService {
  async listMy(page = 0, size = 20): Promise<OrganizationResponse> {
    return (await http.get('/my-organizations', { params: { page, size } })).data
  }
  async list(page = 0, size = 20, search?: string): Promise<OrganizationResponse> {
    return (await http.get('/organizations', { params: { page, size, search: search !== '' ? search : undefined } }))
      .data
  }

  async create(body: CreateOrganizationRequest) {
    return (await http.post('/create-organization', body)).data
  }

  async join(code: string) {
    return (await http.post(`/join-organization/${code}`)).data
  }
}

export default new OrganizationService()
