import axios from 'axios'
import { msalInstance, requestScopes } from './AuthConfig'

export const getBaseUrl = (): string => {
  switch (process.env.REACT_APP_STAGE) {
    case 'dev':
      return 'api-dev.sistemiv.com'
    case 'test':
      return 'api-test.sistemiv.com'
    case 'staging':
      return 'api-staging.sistemiv.com'
    default:
      return 'api.sistemiv.com'
  }
}

export const imageBase = `https://${getBaseUrl()}/iam/api/profile/picture`

const axiosInstance = axios.create({
  baseURL: `https://${getBaseUrl()}/iam/api`,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.request.use(
  async (config) => {
    const instance = msalInstance

    const accessTokenRequest = {
      scopes: requestScopes,
      account: instance.getActiveAccount() ?? undefined,
    }

    const { accessToken } = await instance.acquireTokenSilent(accessTokenRequest)
    if (config.headers) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  },
  (error) => Promise.reject(error),
)

export default axiosInstance
