// @ts-nocheck
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import LoadingLogo from './assets/loading_logo.png'
import './services/i18n'
import { msalInstance } from './services/AuthConfig'
import { EventType } from '@azure/msal-browser'

msalInstance.initialize().then(() => {
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
  }

  msalInstance.enableAccountStorageEvents()

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload?.account) {
      const account = event.payload.account
      msalInstance.setActiveAccount(account)
    }

    if (event.eventType === EventType.LOGOUT_SUCCESS) {
      document.cookie = `refreshIdentity=true; path=/; max-age=3; domain=.sistemiv.com`
      document.cookie = `refreshProcess=true; path=/; max-age=3; domain=.sistemiv.com`
      document.cookie = `refreshOrganization=true; path=/; max-age=3; domain=.sistemiv.com`
    }
  })

  msalInstance.handleRedirectPromise().then((value) => {
    if (value && value.state && value.state !== '') {
      switch (value.state) {
        case 's-process':
          window.location.replace('https://spro-staging.sistemiv.com')
          break
        case 's-identity':
          window.location.replace('https://identity-staging.sistemiv.com')
          break
        default:
          window.location.replace(value.state)
          break
      }
    }
  })
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.Suspense
    fallback={
      <div className='w-screen h-screen flex items-center justify-center'>
        <img src={LoadingLogo} />
      </div>
    }
  >
    <App pca={msalInstance} />
  </React.Suspense>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
