import { useMsal } from '@azure/msal-react'
import React, { FC, useEffect } from 'react'
import { requestScopes } from '../services/AuthConfig'
import { useNavigate } from 'react-router-dom'

const Logout: FC = () => {
  const { instance } = useMsal()
  const navigate = useNavigate()
  useEffect(() => {
    instance
      .acquireTokenSilent({ scopes: requestScopes, account: instance.getActiveAccount() ?? undefined })
      .then(({ idToken }) => {
        instance.logoutRedirect({
          idTokenHint: idToken,
          account: instance.getActiveAccount(),
        })
      })
      .catch((err) => {
        console.error(err)
        navigate('/')
      })
  }, [instance, navigate])

  return <div>Logout</div>
}

export default Logout
