import { Base64SVG, ImgWrapper, NavigationBar } from '@sistemiv/s-components'
import React, { FC, useContext } from 'react'
import { GlobeAltIcon } from '@heroicons/react/24/outline'
import { Menu } from '@headlessui/react'
import { AuthContext } from '../../contexts/AuthContext'
import { Link, useNavigate, useParams } from 'react-router-dom'
import AppBaseLogo from '../../assets/AppBaseLogo'
import { useProfile } from '../../repositories/user/user-profile.repository'
import { imageBase } from '../../services/http-common'
import { useTranslation } from 'react-i18next'

const TopNav: FC = () => {
  const { account, organizations } = useContext(AuthContext)
  const { org } = useParams()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const { data: user } = useProfile({ userId: account?.localAccountId ?? '' })
  const handleLogout = async () => {
    // const { idToken } = await instance.acquireTokenSilent({
    //   scopes: requestScopes,
    //   account: instance.getActiveAccount() ?? undefined,
    // })
    // instance.logoutRedirect({ account: instance.getActiveAccount(), idTokenHint: idToken })
    navigate('/logout')
  }
  const locales = [
    { value: 'en', title: 'English (en)' },
    { value: 'sr', title: 'Srpski (sr)' },
  ]

  const handleChangeLng = (lng: string) => {
    i18n.changeLanguage(lng)
    localStorage.setItem('lng', lng)
  }

  return (
    <NavigationBar className='h-[4.185rem] border-b border-gray-300 flex items-center justify-between'>
      <div className='flex items-center gap-x-2'>
        <AppBaseLogo />
      </div>
      <div className='flex items-center gap-x-2'>
        {/* <NotificationsMenuItem
          unreadNotifications={3}
          labels={{
            notifications: 'Notifications',
            show_unread: 'Show unread',
            mark_all_as_read: 'Mark all as read',
          }}
        /> */}
        {/* <Menu as='div' className='relative'>
          <Menu.Button className='flex items-center justify-center'>
            <svg
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className='w-4 h-4'
            >
              <path
                d='M0 4H4V0H0V4ZM6 16H10V12H6V16ZM0 16H4V12H0V16ZM0 10H4V6H0V10ZM6 10H10V6H6V10ZM12 0V4H16V0H12ZM6 4H10V0H6V4ZM12 10H16V6H12V10ZM12 16H16V12H12V16Z'
                fill='#26282F'
              />
            </svg>
          </Menu.Button>
          <Menu.Items className='absolute right-2 z-10 mt-2 max-w-xs w-screen origin-top-right rounded shadow-xl ring-1 ring-black ring-opacity-5 flex flex-col bg-white py-3 drop-shadow'>
            {applications?.map((app) => (
              <Menu.Item
                key={app.code}
                as='div'
                className='px-3 py-2 hover:bg-sky-50 cursor-pointer'
                onClick={() => window.location.replace(app.url)}
              >
                <AppIcon code={app.code} />
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu> */}
        <Menu as='div' className='relative'>
          <Menu.Button
            name='nav-language'
            className='inline-flex w-full justify-center items-center gap-x-2 px-3 py-2 rounded-md hover:bg-sky-50 active:bg-sky-100'
          >
            <GlobeAltIcon className='h-5 w-5' />
            <div data-testid='current-language'>{i18n.resolvedLanguage}</div>
          </Menu.Button>
          <Menu.Items className='absolute right-2 z-10 mt-2 w-36 origin-top-right flex flex-col bg-white py-3 drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'>
            {locales.map((locale) => (
              <Menu.Item key={`language-${locale.value}`} data-testid='nav-language'>
                {() => (
                  <div
                    onClick={() => handleChangeLng(locale.value)}
                    className='py-2 px-4 cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'
                    data-testid='nav-language'
                  >
                    {locale.title}
                  </div>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
        <Menu as='div' className='relative'>
          {/* <Menu.Button className='flex items-center justify-center'>
            <div
              className={`w-8 h-8 rounded-full text-white flex items-center justify-center text-lg leading-[18px] border-sky-500 border ${
                organizations?.find((o) => o.organizationCode === org)?.icon ? '' : 'bg-[#7b87ae]'
              }`}
            >
              {organizations?.find((o) => o.organizationCode === org)?.icon ? (
                <Base64SVG base64image={organizations?.find((o) => o.organizationCode === org)?.icon ?? ''} />
              ) : (
                <p className='text-md'>
                  {organizations
                    ?.find((o) => o.organizationCode === org)
                    ?.name?.charAt(0)
                    ?.toUpperCase()}
                </p>
              )}
            </div>
          </Menu.Button> */}
          <Menu.Items className='absolute right-2 z-10 mt-2 max-w-xs w-screen origin-top-right rounded shadow-xl ring-1 ring-black ring-opacity-5 flex flex-col bg-white py-3 drop-shadow'>
            {organizations?.map((organization) => (
              <Menu.Item
                onClick={() => navigate(`/${organization.organizationCode}`, { replace: true })}
                key={organization.organizationCode}
                as='div'
                className='flex items-center gap-x-3 px-3 py-2 cursor-pointer hover:bg-sky-50'
              >
                <div
                  className={`w-8 h-8 rounded-full flex items-center justify-center border ${
                    organization.organizationCode === org ? 'border-sky-500' : 'border-gray-300'
                  } ${!organization.icon ? 'bg-[#7b87ae]' : ''}`}
                >
                  {organization.icon ? (
                    <Base64SVG base64image={organization.icon} />
                  ) : (
                    <p className='text-md text-white'>{organization.name.charAt(0).toUpperCase()}</p>
                  )}
                </div>

                {organization.name}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
        <Menu as='div' className='relative'>
          <Menu.Button className='flex items-center justify-center'>
            <div className='bg-[#7b87ae] w-8 h-8 rounded-full text-white flex items-center justify-center text-lg leading-[18px] border-sky-500 border'>
              <ImgWrapper
                className='w-full h-full rounded-full'
                src={`${imageBase}/${user?.data.id}/76`}
                onErrorComponent={<p>{user?.data.firstName?.charAt(0)?.toUpperCase()}</p>}
              />
            </div>
          </Menu.Button>
          <Menu.Items className='absolute right-2 z-10 mt-2 w-56 origin-top-right flex flex-col bg-white py-3 drop-shadow  rounded shadow-xl ring-1 ring-black ring-opacity-5'>
            <Menu.Item as='div'>
              <Link
                to={`https://identity${
                  process.env.REACT_APP_STAGE !== 'prod' ? `-${process.env.REACT_APP_STAGE}` : ''
                }.sistemiv.com`}
              >
                <div className='py-2 px-4 cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'>
                  {t('myProfile')}
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item as='div' onClick={() => handleLogout()}>
              <div className='py-2 px-4 cursor-pointer w-full hover:bg-sky-50 active:bg-sky-100'>{t('logout')}</div>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </NavigationBar>
  )
}

export default TopNav
